<template>
  <div class="room-form">
    <!-- <el-form ref="ruleFormRef" :model="roomRuleForm" :rules="roomListRules" label-width="120px" class="demo-ruleForm"
      :size="formSize"> -->
    <el-form-item label-width="90px" label="房间编号：" prop="roomNum">
      <el-input v-model="roomRuleForm.roomNum" />
    </el-form-item>
    <el-form-item class="bedNum" label-width="90px" label="床位编号：" prop="bedNum">
      <el-input placeholder="以逗号分割" v-model="roomRuleForm.bedNum"></el-input>
    </el-form-item>
    <div v-if="!isFirst" @click="handleRemoveClick" class="remove">删除</div>
    <div v-else class="placeholder">删除</div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { roomListRules } from '../../../../config'
export default defineComponent({
  props: {
    roomRuleForm: {
      type: Object,
      default() {
        return {}
      }
    },
    isFirst: {
      type: Boolean,
      default: false
    }
  },
  components: {

  },
  emits: ['handleRemoveClick'],
  setup(props, { emit }) {

    const handleRemoveClick = () => {
      emit('handleRemoveClick')
    }

    return {
      handleRemoveClick,
      roomListRules
    }

  }
})
</script>

<style scoped lang="less">
.room-form {
  display: flex;

  .placeholder {
    opacity: 0;
    width: 100px;
  }

  &:deep(.el-input) {
    min-width: 120px !important;
  }

  .bedNum {
    margin-left: 20px;
  }

  .remove {
    width: 100px;
    text-align: center;
    line-height: 40px;
    font-size: 13px;
    color: rgb(64, 158, 255);
    cursor: pointer;
  }
}
</style>
