<template>
  <div @click="handleElFormClick" class="page-form">
    <div class="left">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="addShopRule" label-width="120px" class="demo-ruleForm"
        :size="formSize">
        <div class="title">基础信息</div>
        <el-form-item label="门店区域：" prop="shopProv">
          <div class="shop-area">
            <el-form-item>
              <el-select v-model="ruleForm.shopProv" class="m-2" placeholder="请选择所在省" size="large"
                @change="handleShopProvChange">
                <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="shopCity">
              <el-select v-model="ruleForm.shopCity" class="m-2" placeholder="请选择所在市" size="large"
                @change="handleshopCityChange">
                <el-option v-for="item in shopCityList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item prop="shoparea">
              <el-select v-model="ruleForm.shoparea" class="m-2" placeholder="请选择所在区" size="large">
                <el-option v-for="item in shopareaList" :key="item.id" :label="item.fullname" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item> -->
          </div>
        </el-form-item>
        <el-form-item class="detail-adress-wrap" label="详细地址：" prop="shopAddr">
          <el-input @clear="handleShopAddrClear" clearable @input="handleDetailAdressInput"
            @focus="handleDttailAddressFocus" @blur="handleDetailAddressBlur" v-model="ruleForm.shopAddr"
            placeholder="请输入详细地址" />
          <div v-show="mapForm.isShowResultView" id="address-result">
            <template :key="item" v-for="item in mapForm.addresResult">
              <div @click="addressListClick(item)">
                <el-icon>
                  <position />
                </el-icon>
                {{ item.title }}-{{ item.address }}
              </div>
            </template>
          </div>
        </el-form-item>
        <el-form-item label="门店名称：" prop="shopName">
          <el-input v-model="ruleForm.shopName" placeholder="请输入门店名称" />
        </el-form-item>
        <div class="title">营业信息</div>
        <el-form-item label="门店介绍：" prop="shopIntro">
          <el-input :maxlength="200" show-word-limit v-model="ruleForm.shopIntro" :rows="4" type="textarea"
            placeholder="请输入门店介绍" />
        </el-form-item>
        <el-form-item label="营业时间：" prop="shopRuntime">
          <div class="run-time">
            <el-time-select v-model="ruleForm.runStartTime" :max-time="ruleForm.runEndTime" class="mr-4"
              placeholder="开始时间" start="00:00" step="00:15" end="23:45" />
            <el-time-select v-model="ruleForm.runEndTime" :min-time="ruleForm.runStartTime" placeholder="结束时间"
              start="00:15" step="00:15" end="24:00" />
          </div>
        </el-form-item>
        <el-form-item label="联系电话：" prop="shopPhone">
          <el-input v-model="ruleForm.shopPhone" placeholder="请输入联系电话" />
        </el-form-item>
        <el-form-item label="门店类型：" prop="shopKind">
          <el-select v-model="ruleForm.shopKind" class="m-2" placeholder="请选择门店类型" size="large">
            <el-option v-for="item in shopType" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="门店照片：" prop="pic">
          <upload-img :isAdd="true" v-model="ruleForm.pic" @handleUploadRemove="handleUploadRemove" ref="uploadShopImgRef"
            @handleUploadSuccess="handleUploadChange" :limit="1"></upload-img>
          <div class="img-tips">图片建议尺寸800*600像素,最多1张图片</div>
        </el-form-item>
        <div class="title">坪效信息</div>
        <el-form-item label="开业时间：" prop="openDate">
          <el-date-picker v-model="ruleForm.openDate" type="date" placeholder="请输入开业时间" value-format="YYYY-MM-DD"></el-date-picker>
        </el-form-item>
        <el-form-item label="建筑面积：" prop="shopSquare">
          <el-input v-model="ruleForm.shopSquare" placeholder="请输入建筑面积">
            <template #suffix>| 平方米</template>
          </el-input>
        </el-form-item>
        <el-form-item label="使用面积：" prop="shopUseSquare">
          <el-input v-model="ruleForm.shopUseSquare" placeholder="请输入使用面积">
            <template #suffix>| 平方米</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <div class="tips">
            <div>*床位编号将自动以数字编号</div>
            <div @click="handleAddRoomClick" class="add-room">+新增房间</div>
          </div>
        </el-form-item>

        <div class="room">
          <template :key="item" v-for="(item, index) in roomFormList">
            <room-form @handleRemoveClick="handleRoomRemoveClik(index)" :isFirst="index == 0" :roomRuleForm="item">
            </room-form>
          </template>
        </div>
        <div class="submit">
          <el-button @click="handleSaveShopInfoClick(ruleFormRef)" type="primary">保存门店信息</el-button>
          <div class="submit-tips">注：门店创建后,需手动上线,用户才能浏览</div>
        </div>
      </el-form>
    </div>
    <div class="right">
      <div id="map"></div>
      <div class="tips">也可以直接拖动图标,标记门店位置</div>
    </div>
  </div>
</template>

<script lang="js">
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  nextTick,
  watch
} from 'vue'
import {
  map
} from '@/utils/map'
import {
  debounce
} from 'lodash'
import {
  getCityList
} from '@/service/main/shop'
import {
  useStore
} from 'vuex'
import {
  getKeywordList
} from '@/service/main/shop'
import {
  shopType
} from '../../config'
import {
  COMPANY_ID
} from '@/common/constant'
import {
  MAP_KEY
} from '@/common/constant'
import {
  VueJsonp
} from 'vue-jsonp'
import fetchJsonp from 'fetch-jsonp';
import RoomForm from './cpns/room-form/room-form.vue'
import { shopBelongList } from './config'
import { useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { addShopRule } from '../../config'
import UploadImg from '@/components/page-upload/upload-img/upload-img.vue'

export default defineComponent({
  props: {

  },
  components: {
    RoomForm,
    UploadImg
  },
  methods: {

  },
  setup() {
    const route = useRoute()
    const address = ref('')
    const store = useStore()
    const mapForm = reactive({
      x: 31.235929,
      y: 121.48054,
      addresResult: [],
      isShowResultView: false
    })

    const ruleForm = reactive({
      // shopDistrict: "1",
      shopProv: "",
      shopCity: "",
      // shoparea: "",
      shopAddr: "",
      shopName: "",
      shopKind: "1",
      shopRuntime: "",
      shopPhone: "",
      shopIntro: "",
      openDate: "",
      shopSquare: "",
      shopUseSquare: "",
      lng: "121.48054",
      lat: "31.235929",
      pic: [],
      runStartTime: '',
      runEndTime: ''
    })

    const emptyRuleForm = () => {
      for (let key in ruleForm) {
        if (key != 'pic') {
          ruleForm[key] = ''
        } else {
          ruleForm[key] = []
        }
      }
    }


    const roomFormList = ref([{
      roomNum: '',
      bedNum: ''
    }])


    const handleAddImgUploadRemove = url => {
      // console.log(url);
    }

    const handleDetailAdressInput = debounce(async (value) => {
      // 当输入框为空时
      if (value.length == 0) {
        handleShopAddrClear()
        return
      }

      if (ruleForm.lng != '121.48054') {
        return
      }

      if (value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

      suggest
        .getSuggestions({ keyword: value, location: map.getCenter() })
        .then((result) => {
          mapForm.addresResult = result.data
          if (result.data.length > 0) {
            mapForm.isShowResultView = true
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }, 750)

    const addressListClick = (item) => {
      // console.log(item);
      ruleForm.lng = item.location.lng
      ruleForm.lat = item.location.lat
      mapForm.isShowResultView = false
      if (ruleForm.lng != '121.48054') {
        ruleForm.shopAddr = item.title + '' + item.address
      }

      map.setCenter(item.location);
      markers.setGeometries([]);
      // console.log(item.location);
      markers.updateGeometries([
        {
          id: '0', // 点标注数据数组
          position: item.location,
        },
      ]);
    }

    const handleDetailAddressBlur = (e) => {
      if (e.target.value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

    }

    const handleDttailAddressFocus = (e) => {
      if (e.target.value.trim().length == 0) {
        mapForm.isShowResultView = false
      }

    }

    const provinceList = ref([])
    const shopCityList = ref([])
    const shopareaList = ref([])
    const currentCity = ref('')
    const city = async () => {
      const cityList = await getCityList()
      provinceList.value = cityList.result[0]
      // console.log(cityList)
    }
    city()

    const handleShopProvChange = async (id) => {
      // console.log(id);
      const cityList = await getCityList(id)
      shopCityList.value = cityList.result[0]
      nextTick(() => {
        currentCity.value = (provinceList.value.find(item => item.id == id)).name
      })

    }
    const handleshopCityChange = async (id) => {
      // console.log(event, id);
      const cityList = await getCityList(id)
      shopareaList.value = cityList.result[0]
      nextTick(() => {
        currentCity.value = (shopCityList.value.find(item => item.id == id)).name
      })

    }

    const emptyRoom = () => {
      roomFormList.value = [{
        roomNum: '',
        bedNum: ''
      }]
    }

    const handleSaveShopInfoClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate((valid, fields) => {
        if (valid) {
          let flag = true
          if (roomFormList.value.length == 0) {
            ElMessage({
              message: '最少添加一个床位!',
              type: 'warning',
            })
            return
          }
          roomFormList.value.forEach(item => {
            for (let key in item) {
              if (String(item[key]).trim().length == 0) {
                flag = false
                ElMessage({
                  message: '请填写房间与床位!',
                  type: 'warning',
                })
                break
              }
            }
          })

          let roomList = roomFormList.value
          roomList.forEach(ele => {
            const arr = roomList.filter(item => item.roomNum == ele.roomNum)
            if (arr.length > 1) {
              ElMessage({
                message: '房间编号不得重复！',
                type: 'warning',
              })
              flag = false
            }
          })

          if (flag) {
            store.dispatch('shop/saveShopInfoAction', {
              ...ruleForm,
              roomForm: roomFormList.value
            })
          }


        } else {
          ElMessage({
            message: '带*为必填项，请正确填写数据！',
            type: 'warning',
          })
        }
      })

    }


    let map, search, suggest, markers, geocoder
    const initTxMap = () => {
      // console.log(mapForm.x, mapForm.y);
      map = new TMap.Map('map', {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 16, //设置地图缩放级别
        center: new TMap.LatLng(mapForm.x, mapForm.y),
      });
      search = new TMap.service.Search({ pageSize: 10 }); // 新建一个地点搜索类
      geocoder = new TMap.service.Geocoder(); // 新建一个正逆地址解析类
      suggest = new TMap.service.Suggestion({
        // 新建一个关键字输入提示类
        pageSize: 10, // 返回结果每页条目数
        region: '上海', // 限制城市范围
        regionFix: true, // 搜索无结果时是否固定在当前城市
      });
      markers = new TMap.MultiMarker({
        map: map,
        geometries: [],
      });

      map.on("click", (evt) => {
        markers.setGeometries([]);
        ruleForm.lng = evt.latLng.lng
        ruleForm.lat = evt.latLng.lat
        var location = new TMap.LatLng(evt.latLng.lat, evt.latLng.lng);
        map.setCenter(location);
        markers.updateGeometries([
          {
            id: '0', // 点标注数据数组
            position: evt.latLng,
          },
        ]);

        geocoder
          .getAddress({ location: location }) // 将给定的坐标位置转换为地址
          .then((result) => {
            ruleForm.shopAddr = result.result.formatted_addresses.recommend + '-' + result.result.address;
            // 显示搜索到的地址
          });
      });
    }

    onMounted(() => {
      initTxMap()
    })

    const shopImgFileList = ref([])
    const uploadShopImgRef = ref(null)
    const handleUploadChange = (res, file, fileList) => {
      ruleForm.pic.push(res.data.fileName)
    }

    const handleAddRoomClick = () => {

      roomFormList.value.push({
        roomNum: '',
        bedNum: ''
      })
    }

    const handleRoomRemoveClik = (index) => {
      roomFormList.value.splice(index, 1)
    }

    const handleElFormClick = () => {
      mapForm.isShowResultView = false
    }


    watch(() => route.path, (n) => {
      if (n == '/main/shop/add') {
        uploadShopImgRef.value.emptyFileList()
      }
    })


    const hadleUploadImgExceed = () => {
      ElMessage.error('最多上传5张图片!')
    }

    const ruleFormRef = ref()
    const handleUploadRemove = url => {
      // console.log(url)
      let index = ruleForm.pic.findIndex(item => item == url)
      if (index != -1) {
        ruleForm.pic.splice(index, 1)
      }
    }

    const handleShopAddrClear = () => {
      ruleForm.lng = '121.48054'
      addressListClick({
        location: {
          lng: '121.48054',
          lat: '31.235929'
        }
      })
    }




    return {
      handleShopAddrClear,
      handleUploadRemove,
      handleAddImgUploadRemove,
      ruleFormRef,
      addShopRule,
      hadleUploadImgExceed,
      handleElFormClick,
      shopBelongList,
      address,
      handleDetailAdressInput,
      mapForm,
      addressListClick,
      handleDetailAddressBlur,
      handleDttailAddressFocus,
      provinceList,
      shopCityList,
      ruleForm,
      handleShopProvChange,
      handleshopCityChange,
      shopareaList,
      handleSaveShopInfoClick,
      currentCity,
      shopType,
      handleUploadChange,
      shopImgFileList,
      emptyRuleForm,
      uploadShopImgRef,
      MAP_KEY,
      handleAddRoomClick,
      roomFormList,
      handleRoomRemoveClik

    }

  }
})
</script>

<style scoped lang="less">
.page-form {
  margin: 20px;
  display: flex;
  background-color: #fff;
  padding: 20px;

  .run-time {
    display: flex;
    justify-content: space-between;

    &:deep(.el-select) {
      max-width: 340px;
    }
  }

  .img-tips {
    color: rgba(128, 128, 128, 1) !important;
    font-size: 12px !important;

  }

  .left,
  .right {
    flex: 1;

    .title {
      position: relative;
      padding-left: 12px;
      margin: 24px 0;
      margin-left: 20px;

      &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: 0;
        width: 6px;
        height: 22px;
        background-color: rgba(42, 130, 228, 1);
      }
    }
  }

  .submit {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .submit-tips {
      margin-top: 10px;
      color: rgba(165, 165, 165, 1);
      font-size: 12px;
    }
  }

  .right {
    margin-left: 20px;

    #map {
      width: 350px;
      height: 350px;
      margin-top: 66px;
    }

    .tips {
      color: rgba(80, 80, 80, 1);
      font-size: 12px;
      margin-top: 20px;
    }
  }

  .left {
    .shop-area {
      display: flex;
    //   justify-content: space-between;

      &:deep(.el-select) {
        margin-right: 10px;
      }
    }

    .detail-adress-wrap {
      position: relative;

      #address-result {
        width: 100%;
        position: absolute;
        top: 45px;
        left: 0;
        z-index: 999;
        background-color: #fff;
        border: 1px solid var(--borderColor);
        padding: 10px;
      }
    }

    .tips {
      display: flex;
      justify-content: space-between;
      color: rgba(80, 80, 80, 1);
      background-color: rgba(239, 239, 239, 1);
      font-size: 14px;
      padding: 0 10px;

      .add-room {
        cursor: pointer;
      }
    }

    .room {
      padding-left: 120px;
    }
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-input) {
    width: 100%;
  }
}
</style>
