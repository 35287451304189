<template>
  <div class="shop-add">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <page-form></page-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import PageForm from './cpns/page-form/page-form.vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
export default defineComponent({
  props: {

  },
  components: {
    PageForm,
    Breadcrumb,

  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.shop-add {
  min-width: 980px;
}
</style>




