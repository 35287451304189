export const shopType = [
  {
    value: '1',
    label: '自营'
  },
  {
    value: '2',
    label: '加盟'
  }
]

export const breadcrumbList = [
  {
    name: '门店管理',
    path: ''
  },
  {
    name: '门店列表',
    path: '/main/shop/list'
  },
  {
    name: '添加门店',
    path: ''
  }
]

export const addShopRule = {
  shopDistrict: [
    { required: true, message: '请选择门店归属', trigger: 'blur' }
  ],
  shopProv: [{ required: true, message: '请选择门店省地址', trigger: 'blur' }],
  shopCity: [{ required: true, message: '请选择门店市地址', trigger: 'blur' }],
  shoparea: [{ required: true, message: '请选择门店区地址', trigger: 'blur' }],
  shopName: [{ required: true, message: '请选择门店名称', trigger: 'blur' }],
  shopAddr: [
    { required: true, message: '请输入门店详细地址', trigger: 'blur' }
  ],
  runStartTime: [
    { required: true, message: '请输入门店营业时间', trigger: 'blur' }
  ],
  runEndTime: [
    { required: true, message: '请输入门店营业时间', trigger: 'blur' }
  ],
  shopPhone: [{ required: true, message: '请输入门店电话', trigger: 'blur' }],
  shopKind: [{ required: true, message: '请选择门店类型', trigger: 'blur' }],
  openDate: [{ required: true, message: '请选择开业时间', trigger: 'blur' }],
  shopIntro: [{ required: false, message: '请输入门店介绍', trigger: 'blur' }],
  shopSquare: [{ required: true, message: '请输入建筑面积', trigger: 'blur' }],
  shopUseSquare: [{ required: true, message: '请输入使用面积', trigger: 'blur' }],
  pic: [{ required: true, message: '请上传门店照片', trigger: 'blur' }],
}

export const roomListRules = {
  roomNum: [{ required: true, message: '请输入床位编号', trigger: 'blur' }],
  bedNum: [{ required: true, message: '请输入床位数量', trigger: 'blur' }]
}
