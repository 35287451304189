export const shopBelongList=[
  {
    label:"浦东",
    value:'1'
  },
  {
    label:"浦西",
    value:'2'
  }
]
